import { Grid, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import manS from '../../assets/manS.jpg'
import React from 'react'
import { Client } from '../../redux/reducers/clients'
import { useSelector } from '../../redux/hooks/useSelector'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  manIcon: {
    width: '100%',
  },
}))

interface Props {
  client: Client
  onClientAttributeChange: (name: string) => (value: any) => void
}

export const ClientNameContainer: React.FC<Props> = ({ onClientAttributeChange, client }) => {
  const classes = useStyles()
  const errors = useSelector(state => state.useractions.errors)

  const onClientInputChangeCreator = (name: string) => (event: any) => {
    onClientAttributeChange(name)(event.target.value)
  }

  const { t } = useTranslation()

  return (
    <>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <img alt="" className={classes.manIcon} src={manS} />
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField variant="standard" fullWidth id="standard-basic" label={t('UID')} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  fullWidth
                  required
                  id="firstName"
                  label={t('FIRST_NAME')}
                  onChange={onClientInputChangeCreator('firstName')}
                  value={client.firstName}
                  helperText={errors['surname'] ? 'Toto pole je povinné' : ''}
                  error={errors['surname']}    
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              variant="standard"
              fullWidth
              id="titlePre"
              label={t('TITLE_PRE')}
              onChange={onClientInputChangeCreator('titlePre')}
              value={client.titlePre}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="standard"
              fullWidth
              id="titleAfter"
              label={t('TITLE_AFTER')}
              onChange={onClientInputChangeCreator('titleAfter')}
              value={client.titleAfter}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              fullWidth
              required
              helperText={errors['surname'] ? 'Toto pole je povinné' : ''}
              error={errors['surname']}
              id="surname"
              label={t('SURNAME')}
              onChange={onClientInputChangeCreator('surname')}
              value={client.surname}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
