import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  deactivateClientRequest,
  exportClientsRequest,
  getClientCashflowRequest,
  getClientDealsRequest,
  getClientRequest,
  getClientRolesRequest,
  getClientsByParamRequest,
  getClientsRequest,
  postClientRequest,
  updateClientRequest,
} from '../api/clientAPI'
import { ACTION_FAILED_ALERT_ID, CLIENT_INFORMATION_DIALOG_ID } from '../constants/dialogConstants'
import {
  addClientAction,
  deactivateClientAction,
  getClientAction,
  getClientCashflowsAction,
  getClientCashflowsActionError,
  getClientDealsAction,
  getClientDealsActionError,
  getClientRolesAction,
  getClientsAction,
  getClientsActionAfter,
  getClientsActionBefore,
  updateClientAction,
} from '../redux/actions/clientActions'
import { closeDialog, openDialog } from '../redux/actions/dialogActions'
import { Client, ClientCashflow, ClientDeal } from '../redux/reducers/clients'
import { finishLoading, startLoading } from '../redux/actions/loadingActions'
import { lookup } from 'mime-types'

export const useClientApi = () => {
  const dispatch = useDispatch()

  const getClients = useCallback(async () => {
    dispatch(getClientsActionBefore())

    await getClientsRequest()
      .then(response => {
        dispatch(getClientsAction(response.data))
      })
      .catch(error => {
        console.error(error)
      })
    dispatch(getClientsActionAfter())
  }, [])

  const postClient = useCallback(async (client: Client) => {
    await postClientRequest(client)
      .then(response => {
        dispatch(addClientAction(response.data))
        dispatch(closeDialog(CLIENT_INFORMATION_DIALOG_ID))
      })
      .catch(error => {
        console.error(error)
        dispatch(openDialog(ACTION_FAILED_ALERT_ID))
      })
  }, [])

  const updateClient = useCallback(async (client: Client) => {
    await updateClientRequest(client)
      .then(response => {
        dispatch(updateClientAction(response.data))
        dispatch(closeDialog(CLIENT_INFORMATION_DIALOG_ID))
      })
      .catch(error => {
        console.error(error)
        dispatch(openDialog(ACTION_FAILED_ALERT_ID))
      })
  }, [])

  const deactivateClient = useCallback(async (client: Client) => {
    await deactivateClientRequest(client).then(response => {
      dispatch(deactivateClientAction(response.data))
    })
  }, [])

  const getClient = useCallback(async (clientId: number) => {
    dispatch(getClientsActionBefore())

    await getClientRequest(clientId)
      .then(response => {
        dispatch(getClientAction(response.data as Client))
      })
      .catch(error => {
        console.error(error)
      })

    dispatch(getClientsActionAfter())
  }, [])

  const getClientsByParam =
    useCallback(async (param: string) => {
      await getClientsByParamRequest(param)
        .then(response => {
          dispatch(getClientsAction(response.data as Client[]))
        })
        .catch(error => {
          console.error(error)
        })
    }, []) || []

  const getClientRoles = useCallback(async () => {
    await getClientRolesRequest()
      .then(response => {
        dispatch(getClientRolesAction(response.data))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const getClientDeals = useCallback(async (kid: String, dateFrom: String, dateTo: String) => {
    await getClientDealsRequest(kid, dateFrom, dateTo)
      .then(response => {
        dispatch(getClientDealsAction(response.data as ClientDeal[]))
      })
      .catch(error => {
        console.error(error)
        dispatch(getClientDealsActionError())
      })
  }, [])

  const getClientCashflow = useCallback(async (kid: String, dateFrom: String, dateTo: String) => {
    await getClientCashflowRequest(kid, dateFrom, dateTo)
      .then(response => {
        dispatch(getClientCashflowsAction(response.data as ClientCashflow[]))
      })
      .catch(error => {
        console.error(error)
        dispatch(getClientCashflowsActionError())
      })
  }, [])

    const exportClients = useCallback(async (clientsId: number[]) => {
      dispatch(startLoading('exportFile'))
      await exportClientsRequest(clientsId)
        .then(async response => {
          dispatch(finishLoading('exportFile'))
          const link = document.createElement('a')
          const type = lookup(response.data.name)
          console.warn(type)
          link.href = `data:${type};base64,${response.data.content}`
          link.download = response.data.name
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .catch(error => {
          dispatch(finishLoading('exportFile'))
          dispatch(openDialog(ACTION_FAILED_ALERT_ID))
          console.log(error)
        })
    }, [])

  return {
    getClients,
    getClient,
    postClient,
    updateClient,
    deactivateClient,
    getClientsByParam,
    getClientRoles,
    getClientDeals,
    getClientCashflow,
    exportClients
  }
}
