import { Button } from '@mui/material'
import { INavBarButton } from '../../interfaces/layout'
import { useHistory } from 'react-router-dom'
import { newThemeObject } from '../../newTheme'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import React from 'react'
import { useCurrentPage } from '../../hooks/useCurrentPage'

interface Props {
  button: INavBarButton
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 700,
    background: '#fff',
    justifyContent: 'left',
    padding: '10px',
    gap: '5px',
    borderRadius: newThemeObject.roundness.basic,
    margin: 10,
  },
  title: {
    fontSize: '1.3rem',
    fontWeight: '600',
    color: newThemeObject.palette.main.primary,
    margin: 0,
    marginLeft: theme.spacing(4),
  },
  client: {
    fontWeight: '600',
    fontSize: '0.9rem',
    color: '#000',
    margin: 0,
    marginLeft: theme.spacing(4.5),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    textWrap: 'wrap',
    maxWidth: '80%',
  },
  active: {
    background: newThemeObject.palette.highlight.bg,
    color: newThemeObject.palette.main.primary,
  },
  nonActive: {
    background: '#fff',
    color: '#000',
  },
  button: {
    display: 'flex',
    width: '250px',
    justifyContent: 'left',
    padding: '10px',
    borderRadius: newThemeObject.roundness.small,
    alignItems: 'center',
  },
  iconContainer: {
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: '30px',
    color: '#000',
  },
  text: {
    textAlign: 'left',
    margin: 0,
  },
  wannaBeButtonWrapper: {
    display: 'flex',
    width: 250,
    justifyContent: 'left',
    padding: 10,
    paddingBottom: 0,
    borderRadius: newThemeObject.roundness.small,
    alignItems: 'center',
  },
}))

export const NavBarButton: React.FC<Props> = ({ button }: Props) => {
  const classes = useStyles()
  const history = useHistory()
  const curr = useCurrentPage()

  const onNavButtonClick = (path: string) => () => {
    history.push(path)
  }

  let activClass = curr === button.link ? classes.active : classes.nonActive
  if (
    curr.split('/')[1] === 'client-detail' &&
    !curr.split('/')[3] &&
    button.link?.startsWith('/client-detail') &&
    button.link?.endsWith('0')
  ) {
    activClass = classes.active
  }
  return (
    <>
      {
        ( button.link || button.function ) && 
        <Button
          onClick={button.link ? onNavButtonClick(button.link) : button.function}
          className={classNames(classes.button, activClass)}
        >
          {button.icon && (
            <span className={classes.iconContainer}>{React.cloneElement(button.icon, { className: classes.icon })}</span>
          )}
          <p style={button.icon ? { fontSize: 12 } : { fontSize: 11, marginLeft: 20 }} className={classes.text}>
            {button.text}
          </p>
        </Button>
      }
      {
        !button.link && !button.function && 
        <div className={classes.wannaBeButtonWrapper}>
          {button.icon && (
            <span className={classes.iconContainer}>{React.cloneElement(button.icon, { className: classes.icon })}</span>
          )}
          <p className={classes.text}>{button.text.toLocaleUpperCase()}:</p>
        </div>
      }
    </>
  )
}
