import { Avatar, Button, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import NumberFormat from 'react-number-format'
import { useHistory } from 'react-router-dom'
import { useRiskProfileColor } from '../../hooks/useRiskProfileColor'
import { Client } from '../../redux/reducers/clients'
import { Pages } from '../../router/router'
import IconButton from '@mui/material/IconButton'
import { openDialog } from '../../redux/actions/dialogActions'
import { CLIENT_INFORMATION_DIALOG_ID } from '../../constants/dialogConstants'
import Edit from '@mui/icons-material/Edit'
import { useDispatch } from 'react-redux'
import CardWrapper from '../common/CardWrapper'
import { formatNumber } from '../../utils/numbersFormatter'
import addQuarters from 'date-fns/addQuarters'
import { newThemeObject } from '../../newTheme'
import { TransparentButton } from '../common/TransparentButton'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  top: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
  },
  name: {
    fontWeight: 600,
    color: newThemeObject.palette.main.primary,
    margin: 0,
    fontSize: '0.8rem',
  },
  subtext: {
    fontSize: '0.6rem',
    margin: 0,
  },
  phoneNumber: {
    fontSize: '0.6rem',
    color: newThemeObject.palette.main.lightGray,
    margin: 0,
  },
  amount: {
    margin: 0,
    marginTop: theme.spacing(2),
    textAlign: 'right',
    fontWeight: 500,
    fontSize: '0.8rem',
  },
  button: {
    borderRadius: newThemeObject.roundness.basic,
    padding: 0,
    cursor: 'pointer',
  },
  iconButton: {
    width: '1.5rem',
    height: '1.5rem',
  },
  avatar: {
    width: '3.4rem',
    height: '3.4rem',
  },
  whole: {
    width: 335,
    padding: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 'auto',
  },
  client: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
}))

interface Props {
  client: Client
}

export const ClientTile: React.FC<Props> = ({ client }) => {
  const classes = useStyles()
  const history = useHistory()
  const riskProfileColor = useRiskProfileColor(client)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const onWholeClick = () => {
    history.push(Pages.ClientDetail + `/${client.clientId}`)
  }

  const onEditClick = () => {
    dispatch(
      openDialog(CLIENT_INFORMATION_DIALOG_ID, {
        dialogTitle: t('CLIENT_EDIT'),
        client,
        type: 'edit',
      })
    )
  }

  let avatarPlaceholder = ''

  if (client.firstName) {
    avatarPlaceholder += client.firstName.charAt(0)
  }

  if (client.surname) {
    avatarPlaceholder += client.surname.replace(' ', '').charAt(0)
  }
  return (
    <div className={classes.button} onClick={onWholeClick}>
      <CardWrapper className={classes.whole}>
        <span className={classes.top}>
          <span className={classes.client}>
            <Avatar className={classes.avatar}>{avatarPlaceholder}</Avatar>
            <span>
              <p className={classes.name}>
                {client.company &&
                  <>
                    {client.companyName}
                    <p className={classes.subtext}>{client.firstName} {client.surname}</p>
                  </>
                }
                {!client.company &&
                  <>{client.firstName} {client.surname}</>
                }
              </p>
              <p className={classes.phoneNumber}>
                +{formatNumber(parseInt(client.telephoneNumber || ''), { decimalPlaces: 0 })}
              </p>
            </span>
          </span>
          <IconButton
            onClick={e => {
              e.stopPropagation()
              onEditClick()
            }}
            className={classes.iconButton}
          >
            <Edit />
          </IconButton>
        </span>
        <p className={classes.amount}>
          {formatNumber(
            Math.round(
              ((client && client.portfolios && client.portfolios.length > 0
                ? client.portfolios[0].amount || 0.0
                : 0.0) +
                Number.EPSILON) *
                100
            ) / 100
          )}{' '}
          {client && client.portfolios && client.portfolios.length > 0 ? client.portfolios[0].currency : ''}
        </p>
      </CardWrapper>
    </div>
  )
}
