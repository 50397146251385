import { Box, Paper, Tab, Tabs, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useSelector } from '../redux/hooks/useSelector'
import { useClientApi } from '../hooks/useClientApi'
import { useLabelApi } from '../hooks/useLabelApi'
import { useDispatch } from 'react-redux'
import { useInstrumentApi } from '../hooks/useInstrumentApi'
import { useCurrencyApi } from '../hooks/useCurrencyApi'
import NumberFormat from 'react-number-format'
import { Portfolio} from '../redux/reducers/portfolio'
import { styled } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { OrderInputDialog } from '../components/dialogs/OrderInputDialog'
import { FileImportDialog } from '../components/dialogs/FileImportDialog'
import { ActionFailedAlert } from '../components/dialogs/ActionFailedAlert'
import { ActionAlert } from '../components/dialogs/ActionAlert'
import { useHistory } from 'react-router-dom'
import { OrderDetailDialog } from '../components/dialogs/OrderDetailDialog'
import Pagination from '../components/common/Pagination'
import cs from 'apexcharts/dist/locales/cs.json'
import { LoadingIndicator } from '../components/common/LoadingIndicator'
import { getClientIdFromUser, isClientUser } from '../services/keycloak.service'
import { Client } from '../redux/reducers/clients'
import { NewLabelDialog } from '../components/dialogs/NewLabelDialog'
import { NEW_LABEL_DIALOG_ID, REMOVE_LABEL_DIALOG_ID } from '../constants/dialogConstants'
import { openDialog } from '../redux/actions/dialogActions'
import { Label } from '../redux/reducers/label'
import { RemoveLabelDialog } from '../components/dialogs/RemoveLabelDialog'
import { ChoiceLabelDialog } from '../components/dialogs/ChoiceLabelDialog'
import { NewLayout } from '../components/newLayout/NewLayout'
import { newThemeObject } from '../newTheme'
import AreaChart from '../components/ClientDetail/AreaChart'
import DonutChart from '../components/ClientDetail/DonutChart'
import LabelsBar from '../components/ClientDetail/LabelsBar'
import Notes from '../components/ClientDetail/Notes'
import DataTable from '../components/ClientDetail/DataTable'

const useStyles = makeStyles(theme => ({
  test: {
    background: 'red',
    width: '200px',
    position: 'absolute',
    top: '20px',
    left: '-50px',
    opacity: '0.95',
    textAlign: 'center',
    lineHeight: '25px',
    fontSize: '16px',
    letterSpacing: '1px',
    color: '#f0f0f0',
    transform: 'rotate(-45deg)',
    paddingRight: '18px',
  },
  clientEssentials: {
    width: '25%',
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
  manIcon: {
    marginTop: theme.spacing(1),
  },
  clientPersonalInfoColumn: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1),
  },
  detailPortfolio: {
    flexGrow: 1,
    padding: theme.spacing(1),
    width: '100%',
  },
  fontSize: {
    fontSize: '13px',
  },
  fontSizeHeader: {
    fontSize: '16px',
  },
  fontSizeLegend: {
    fontSize: '12px',
  },
  cellString: {
    color: theme.palette.primary.dark,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  cellNumber: {
    color: theme.palette.primary.dark,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'end',
    paddingLeft: theme.spacing(5),
  },
  cell: {
    color: theme.palette.primary.dark,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingLeft: theme.spacing(5),
  },
  dp: {
    color: '#6592ce',
    fontSize: '1.1rem',
    paddingTop: theme.spacing(2),
    fontWeight: 400,
  },
  dp2: {
    color: '#6592ce',
    fontSize: '1.0rem',
    paddingTop: theme.spacing(2),
  },
  indicator: {
    backgroundColor: '#6592ce',
    color: '#6592ce',
  },
  activeTabColor: {
    '&.Mui-selected': {
      color: '#6592ce',
    },
  },
  flexRow90: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    color: '#6592ce',
    fontSize: 'small',
    fontWeight: 400,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    alignItems: 'flex-start',
  },
  flexItem: {
    color: 'black',
  },
  activeLabelTab: {
    color: '#6592ce',
    borderBottom: '2px solid #6592ce',
    paddingLeft: '5px',
    paddingRight: '16px',
  },
  activeLabelTab2: {
    color: '#6592ce',
    borderBottom: '2px solid #6592ce',
    paddingLeft: '5px',
  },
  unactiveLabelTab: {
    color: '#6592ce',
    paddingLeft: '5px',
    paddingRight: '16px',
  },
  unactiveLabelTab2: {
    color: '#6592ce',
    paddingLeft: '5px',
  },
  flexRowLabel: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    color: '#6592ce',
    paddingTop: '20px',
    borderBottom: '1px solid #A0A0A0',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  cashflow1: {
    color: theme.palette.primary.dark,
  },
  cashflowSubtitle: {
    color: theme.palette.primary.dark,
    fontSize: 15,
  },
  cashflow2: {
    color: theme.palette.primary.main,
    paddingLeft: '5px',
  },
  cashflow3: {
    color: 'green',
    paddingLeft: '50px',
    margin: '-10px',
  },
  topContentPC: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    gap: theme.spacing(2),
  },
  topBarCard: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: newThemeObject.roundness.basic,
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    gap: theme.spacing(1),
  },
  topTwoBar: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: newThemeObject.palette.main.primary,
    fontWeight: 600,
    fontSize: '1rem',
    margin: 0,
    padding: 0,
  },
  whole: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  titles: {
    color: newThemeObject.palette.main.primary,
    margin: 0,
    fontSize: '1.2rem',
    fontWeight: 600,
  },
  totalText: {
    backgroundColor: newThemeObject.palette.main.primary,
    margin: 0,
    color: '#fff',
    height: '2rem',
    borderRadius: '1rem',
    padding: '1rem 1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.2rem',
    fontWeight: 600,
  },
  topBar: {
    margin: '0rem 2rem',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1.2rem',
    fontWeight: 600,
  },
}))

const ribon = process.env.REACT_APP_ENV_RIBBON

function formattedNumber(val: number) {
  if (val > 1000000)
    //10 MIO
    return (val / 1000000).toFixed(2).toLocaleString() + ' mio'
  else if (val > 100000)
    //100 K
    return (val / 1000).toFixed(2).toLocaleString() + ' tis'
  else return val.toFixed(2).toLocaleString()
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

const getPieChartOptions = (labels: string[], total: number, currency: string): ApexOptions => {
  return {
    labels,
    chart: {
      type: 'donut',
      toolbar: {
        show: false,
      },
      height: 350,
      locales: [cs], //or multi language like [fa, en]
      defaultLocale: 'cs',
      fontFamily:
        'Poppins, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
        expandOnClick: false,
        donut: {
          size: '95%',
          labels: {
            show: true,
            name: {
              show: true,
            },
            value: {
              show: true,
              fontWeight: 600,
              fontSize: '2rem',
            },
            total: {
              fontSize: '0.8rem',
              fontWeight: 600,
              color: '#4562ab',
              show: true,
              label: currency,
              formatter: function (w) {
                return formattedNumber(total)
              },
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'center',
    },
    colors: newThemeObject.palette.graphColors,
  }
}

interface DonutChartProps {
  graphBarIndex: number
  handleChangeAggType: (event: React.ChangeEvent<{}>, newValue: number) => void
  optionsGraphBar: ApexOptions
  seriesGraphBar: Array<number>
  index?: number
  handleChangeIndex?: (index: number) => void
  client: Client
  portfolio: Portfolio
}

export const DonutChartOld: React.FC<DonutChartProps> = ({
  graphBarIndex,
  handleChangeAggType,
  optionsGraphBar,
  seriesGraphBar,
  handleChangeIndex,
  index,
  client,
  portfolio,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Item>
      <Box
        sx={theme => ({
          borderBottom: 1,
          borderColor: 'divider',
          marginBottom: '30px',
        })}
        display={{ xs: 'none', md: 'flex' }}
      >
        <Tabs
          value={graphBarIndex}
          onChange={handleChangeAggType}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          style={{
            width: '100%',
            color: '#8eabff',
            textTransform: 'none',
          }}
        >
          <Tab
            label={<Typography className={classes.cellString}>{t('ASSET_AMOUNT')}</Typography>}
            color="primary"
            {...a11yProps(0)}
            disabled
            style={{
              width: '30%',
              color: '#8eabff',
              textTransform: 'none',
            }}
          />
          <Tab
            label={<Typography className={classes.cellString}>{t('INSTRUMENT_TYPE')}</Typography>}
            {...a11yProps(1)}
            className={classes.activeTabColor}
            style={{
              color: '#8eabff',
              textTransform: 'none',
              minWidth: '60px',
            }}
          />
          <Tab
            label={<Typography className={classes.cellString}>{t('ASSET_CLASS')}</Typography>}
            {...a11yProps(2)}
            className={classes.activeTabColor}
            style={{
              color: '#8eabff',
              textTransform: 'none',
              minWidth: '60px',
            }}
          />
          <Tab
            label={<Typography className={classes.cellString}>{t('CURRENCY')}</Typography>}
            {...a11yProps(3)}
            className={classes.activeTabColor}
            style={{
              color: '#8eabff',
              textTransform: 'none',
              minWidth: '60px',
            }}
          />
          <Tab
            label={<Typography className={classes.cellString}>{t('RISK_PROFILE')}</Typography>}
            {...a11yProps(3)}
            className={classes.activeTabColor}
            style={{
              color: '#8eabff',
              textTransform: 'none',
              minWidth: '60px',
            }}
          />
          <Tab
            label={<Typography className={classes.cellString}>{t('HORIZONTE')}</Typography>}
            {...a11yProps(3)}
            className={classes.activeTabColor}
            style={{
              color: '#8eabff',
              textTransform: 'none',
              minWidth: '60px',
            }}
          />
          <Tab
            label={<Typography className={classes.cellString}>{t('SUMMARIZATION')}</Typography>}
            {...a11yProps(3)}
            className={classes.activeTabColor}
            style={{
              color: '#8eabff',
              textTransform: 'none',
              minWidth: '60px',
            }}
          />
        </Tabs>
      </Box>
      <Box
        sx={theme => ({
          display: 'flex',
          flexDirection: 'row',
          height: 350,
          justifyContent: 'center',
          alignItems: graphBarIndex === 6 ? 'flex-start' : 'center',
        })}
      >
        {handleChangeIndex && <Pagination dots={2} index={index} onChangeIndex={handleChangeIndex} />}

        {graphBarIndex !== 6 && (
          <div
            style={{
              width: '100%',
            }}
          >
            <ReactApexChart options={optionsGraphBar} series={seriesGraphBar} type="donut" height={350} />
          </div>
        )}
        {graphBarIndex === 6 && (
          <div className={classes.flexRow90}>
            <div className={classes.flexColumn}>
              <div>&nbsp;</div>
              <div>{t('HORIZONTE')}:</div>
              <div>{t('RISK')}:</div>
              <div>{t('CATEGORY')}:</div>
              <div>{t('EXPERIENCE')}:</div>
              <div>{t('PREFERENCE')}:</div>
              <div>{t('RISK3')}:</div>
            </div>

            <div className={classes.flexColumn}>
              <div>{t('CLIENT')}</div>
              <div className={classes.flexItem}>{client.investmentHorizonte}</div>
              <div className={classes.flexItem}>{client.riskProfile}</div>
              <div className={classes.flexItem}>{t(client.category || '')}</div>
              <div className={classes.flexItem}>{t(client.skillAndExperience || '')}</div>
              <div className={classes.flexItem}>{t(client.preference || '')}</div>
              <div className={classes.flexItem}>{t(client.lossAbility || '')}</div>
            </div>

            <div className={classes.flexColumn}>
              <div>{t('PORTFOLIO')}</div>
              <div className={classes.flexItem}>
                <NumberFormat
                  value={portfolio.horizonteValue || 0}
                  displayType="text"
                  thousandSeparator=" "
                  fixedDecimalScale
                  decimalScale={2}
                  decimalSeparator=","
                />
              </div>
              <div className={classes.flexItem}>
                <NumberFormat
                  value={portfolio.riskValue || 0}
                  displayType="text"
                  thousandSeparator=" "
                  fixedDecimalScale
                  decimalScale={2}
                  decimalSeparator=","
                />
              </div>
              <div className={classes.flexItem}>
                <NumberFormat
                  value={(portfolio.categoryValue || 0) > 100 ? 100 : portfolio.categoryValue || 0}
                  displayType="text"
                  thousandSeparator=" "
                  fixedDecimalScale
                  decimalScale={2}
                  decimalSeparator=","
                />{' '}
                %
              </div>
              <div className={classes.flexItem}>
                <NumberFormat
                  value={(portfolio.skillAndExperienceValue || 0) > 100 ? 100 : portfolio.skillAndExperienceValue || 0}
                  displayType="text"
                  thousandSeparator=" "
                  fixedDecimalScale
                  decimalScale={2}
                  decimalSeparator=","
                />{' '}
                %
              </div>
              <div className={classes.flexItem}>
                <NumberFormat
                  value={(portfolio.preferenceValue || 0) > 100 ? 100 : portfolio.preferenceValue || 0}
                  displayType="text"
                  thousandSeparator=" "
                  fixedDecimalScale
                  decimalScale={2}
                  decimalSeparator=","
                />{' '}
                %
              </div>
              <div className={classes.flexItem}>
                <NumberFormat
                  value={(portfolio.lossAbilityValue || 0) > 100 ? 100 : portfolio.lossAbilityValue || 0}
                  displayType="text"
                  thousandSeparator=" "
                  fixedDecimalScale
                  decimalScale={2}
                  decimalSeparator=","
                />{' '}
                %
              </div>
            </div>
          </div>
        )}
      </Box>
    </Item>
  )
}

interface GraphBarProps {
  options: ApexOptions
  series: []
}

export const ClientDetail = () => {
  const classes = useStyles()

  const { clientId } = useParams<{ clientId: string }>()
  const { portfolio } = useParams<{ portfolio: string }>()
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (isFirstRender.current === false) {
      setSelectedPortfolioIndex(parseInt(portfolio) || 0)
      updateAggData(graphBarIndex || 1, parseInt(portfolio) || 0)
      updateData(graphLineIndex || 1, parseInt(portfolio) || 0, true)
    } else {
      isFirstRender.current = false
    }
  }, [portfolio])

  const getClientId = () => {
    if (isClientUser()) {
      return getClientIdFromUser()
    } else {
      return clientId
    }
  }

  const clientId2 = getClientId()
  const client = useSelector(state => state.clients.client)
  const { getClient } = useClientApi()
  // const [reloadClient, setReloadClient] = useState(false);

  useEffect(() => {
    getClient(Number(clientId2))
  }, [])

  // useEffect(() => {
  //     if ( reloadClient )
  //         getClient(Number(clientId2))
  // }, [reloadClient]);

  const { getLabels } = useLabelApi()
  const { getRates } = useCurrencyApi()
  const loading = useSelector(state => state.clients.loading)
  const labels = useSelector(state => state.labelState.labels)

  const [labelTab, setLabelTab] = useState(0)
  const [labelMenuColor, setLabelMenuColor] = useState('')
  const [labelMenuName, setLabelMenuName] = useState('')
  const [labelMenuLabelId, setLabelMenuLabelId] = useState(-1)
  const [labelNewTab, setLabelNewTab] = useState(true)

  const { getPositions } = useInstrumentApi()
  const dispatch = useDispatch()

  const positions = useSelector<any>(state => state.securities.positions)

  const [selectedPortfolioIndex, setSelectedPortfolioIndex] = React.useState(portfolio ? Number(portfolio) : 0)
  const [portfolios, setPortfolios] = React.useState<any[]>([])

  const [graphBarIndex, setGraphBarIndex] = React.useState(1)
  const [graphBar, setGraphBar] = React.useState<GraphBarProps>({
    series: [],
    options: {
      fill: {
        type: 'gradient',
        colors: newThemeObject.palette.graphColors,
      },
      colors: newThemeObject.palette.graphColors,
    },
  })

  const [graphLineIndex, setGraphLineIndex] = React.useState(1)
  const [optionsGraphLine, setOptionsGraphLine] = React.useState<ApexOptions>({
    chart: {
      id: 'area-datetime',
      height: 350,
      zoom: {
        autoScaleYaxis: false,
      },
      toolbar: {
        show: true,
      },
      locales: [cs], //or multi language like [fa, en]
      defaultLocale: 'cs',
    },
    grid: {
      show: false,
      borderColor: '#775DD0',
      strokeDashArray: 8,
      position: 'back',
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    annotations: {
      yaxis: [
        {
          y: 30,
          borderColor: '#999',
          label: {
            text: '',
            style: {
              color: '#fff',
              background: '#00E396',
            },
          },
        },
      ],
      xaxis: [
        {
          x: new Date(new Date().getFullYear(), 0, 1).getTime(),
          borderColor: '#999',
          label: {
            text: '',
            style: {
              color: '#fff',
              background: '#775DD0',
            },
          },
        },
      ],
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: 'datetime',
      min: new Date(new Date().getFullYear(), 0, 1).getTime(),
      tickAmount: 2,
    },

    yaxis: {
      labels: {
        formatter: function (val) {
          return formattedNumber(val)
        },
      },
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy',
      },
      y: {
        formatter: function (val) {
          return formattedNumber(val)
        },
      },
    },
    stroke: {
      width: 0,
    },
    fill: {
      type: 'gradient',
      gradient: {
        colorStops: [
          {
            offset: 0,
            color: newThemeObject.palette.main.primary, // Red color at the start of the gradient
            opacity: 1,
          },
          {
            offset: 100,
            color: newThemeObject.palette.main.primary, // Red color at the end of the gradient
            opacity: 0.1,
          },
        ],
      },
    },
    colors: newThemeObject.palette.graphColors,
  })
  const [seriesGraphLine, setSeriesGraphLine] = React.useState([
    {
      data: [],
    },
  ])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedPortfolioIndex(newValue)
    updateAggData(graphBarIndex, newValue)
    updateData(graphLineIndex, newValue, true)
  }

  const handleChangeAggType = (event: React.ChangeEvent<{}>, newValue: number) => {
    setGraphBarIndex(newValue)
    updateAggData(newValue)
  }

  const handleChangePortfolioAmount = (event: React.ChangeEvent<{}>, newValue: number) => {
    setGraphLineIndex(newValue)
    updateData(newValue)
  }

  const { t } = useTranslation()

  useEffect(() => {
    getClient(Number(clientId))
    getSecurities()
  }, [])

  const securities = useSelector(state => state.securities.securities)
  const { getSecurities } = useInstrumentApi()

  // setLabelSecurity({securityId: tmp?.securityId, securityName: tmp?.name});
  const [labelSecurity, setLabelSecurity] = useState({ securityId: 0, securityName: '' })

  function sortPortfolios(portfolios: any[]) {
    if (portfolios) {
      portfolios.sort((a, b) => {
        if (a.portfolioId && b.portfolioId) {
          return a.name.localeCompare(b.name)
        }
        if (!a.portfolioId) {
          return -100
        }
        return 100
      })
    }
    return portfolios
  }

  function postProcessPortfolios(portfolios: any[]) {
    for (let i = 0; i < portfolios.length; i++) {
      if (!portfolios[i].portfolioId) {
        portfolios[i].name = t('ALL_PORTFOLIOS')
      }
    }
    return portfolios
  }

  const getFiltredItem = (labels: any[], portfolio: any, labelTab: any, accountAmount: any, itemName: string) => {
    let result = 0
    if (labelTab === 0) {
      result = portfolio && portfolio.incomeCashFlow ? portfolio.incomeCashFlow[itemName] + accountAmount : 0
    } else {
      if (labels && portfolio.incomeCashFlow.positions) {
        for (let i = 0; i < labels[labelTab - 1].securities.length; i++) {
          for (let j = 0; j < portfolio.incomeCashFlow.positions.length; j++) {
            if (labels[labelTab - 1].securities[i].securityId === portfolio.incomeCashFlow.positions[j].securityId) {
              result += portfolio.incomeCashFlow.positions[j][itemName]
            }
          }
        }
      }
    }
    return result
  }

  useEffect(() => {
    const sortedPortfilios = postProcessPortfolios(sortPortfolios(client.portfolios))
    setPortfolios(sortedPortfilios)

    if (sortedPortfilios && sortedPortfilios[selectedPortfolioIndex]) {
      let tmp: any[] = []
      // let accountAmount = sortedPortfilios[selectedPortfolioIndex].assetClassPosition.filter((item:any)=>item.type === 'MM')[0].amount;

      // let amountCZK = getFiltredItem(labels, sortedPortfilios[selectedPortfolioIndex], labelTab, accountAmount, 'amountCZK');
      let amountCZK = 0

      const currentYear = new Date().getFullYear()

      tmp.push([new Date().getTime(), amountCZK])
      tmp.push([
        new Date(currentYear, 11, 31).getTime(),
        amountCZK + getFiltredItem(labels, sortedPortfilios[selectedPortfolioIndex], labelTab, 0, 'incomeThisYearCZK'),
      ])
      tmp.push([
        new Date(currentYear + 1, 11, 31).getTime(),
        amountCZK + getFiltredItem(labels, sortedPortfilios[selectedPortfolioIndex], labelTab, 0, 'incomeNextYearCZK'),
      ])
      tmp.push([
        new Date(currentYear + 5, 11, 31).getTime(),
        amountCZK + getFiltredItem(labels, sortedPortfilios[selectedPortfolioIndex], labelTab, 0, 'income5YearCZK'),
      ])
      tmp.push([
        new Date(currentYear + 10, 11, 31).getTime(),
        amountCZK + getFiltredItem(labels, sortedPortfilios[selectedPortfolioIndex], labelTab, 0, 'income10YearCZK'),
      ])

      setSeriesGraphLine([
        {
          // @ts-ignore
          name: 'Majetek',
          // @ts-ignore
          data: tmp,
        },
      ])

      const labels2 = sortedPortfilios[selectedPortfolioIndex].instrumentPosition.map((a: any) => {
        return t(a.type)
      })
      setGraphBar({
        series: sortedPortfilios[selectedPortfolioIndex].instrumentPosition.map((a: any) => {
          return a.percent
        }),
        options: getPieChartOptions(
          labels2,
          sortedPortfilios[selectedPortfolioIndex].amount,
          sortedPortfilios[selectedPortfolioIndex].currency
        ),
      })
      getLabels(sortedPortfilios[selectedPortfolioIndex].portfolioId)
    }
  }, [client, labelTab])

  function addMonths(date: Date, months: number) {
    let d = date.getDate()
    date.setMonth(date.getMonth() + +months)
    if (date.getDate() != d) {
      date.setDate(0)
    }
    return date
  }

  const updateAggData = (timeline: number, selectedPortfolioNewIndex?: number) => {
    const index = selectedPortfolioNewIndex !== undefined ? selectedPortfolioNewIndex : selectedPortfolioIndex
    let graphBarPositions = portfolios[index].instrumentPosition
    switch (timeline) {
      case 2:
        graphBarPositions = portfolios[index].assetClassPosition
        break
      case 3:
        graphBarPositions = portfolios[index].currencyPosition
        break
      case 4:
        graphBarPositions = portfolios[index].riskPosition
        break
      case 5:
        graphBarPositions = portfolios[index].horizontePosition
        break
    }
    setGraphBar({
      series: graphBarPositions.map((a: any) => {
        return a.percent
      }),
      options: getPieChartOptions(
        graphBarPositions.map((a: any) => {
          return t(a.type)
        }),
        portfolios[index].amount,
        portfolios[index].currency + getSpaces(index)
      ),
    })
  }
  //hack to rerender chart each time when portoflio is changed
  const getSpaces = (count: number) => {
    let s = ''
    for (let i = 0; i < count; i++) {
      s = s + ' '
    }
    return s
  }

  const updateData = (timeline: number, selectedPortfolioNewIndex?: number, updateSeries?: boolean) => {
    if (updateSeries) {
      const index = selectedPortfolioNewIndex != undefined ? selectedPortfolioNewIndex : selectedPortfolioIndex
      setSeriesGraphLine([
        {
          // @ts-ignore
          name: 'Majetek',
          data: portfolios[index].amountHistory.map((a: any) => {
            return [new Date(a.date).getTime(), a.amount]
          }),
        },
      ])
    }
    switch (timeline) {
      case 1:
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          new Date(new Date().getFullYear(), 0, 1).getTime(),
          new Date(new Date()).getTime()
        )
        break
      case 2:
        ApexCharts.exec('area-datetime', 'zoomX', addMonths(new Date(), -1).getTime(), new Date().getTime())
        break
      case 3:
        ApexCharts.exec('area-datetime', 'zoomX', addMonths(new Date(), -3).getTime(), new Date().getTime())
        break
      case 4:
        ApexCharts.exec('area-datetime', 'zoomX', addMonths(new Date(), -6).getTime(), new Date().getTime())
        break
      case 5:
        ApexCharts.exec('area-datetime', 'zoomX', addMonths(new Date(), -12).getTime(), new Date().getTime())
        break
      case 6:
        ApexCharts.exec('area-datetime', 'zoomX', addMonths(new Date(), -36).getTime(), new Date().getTime())
        break
      default:
    }
  }

  const [isHovered, setIsHovered] = useState(-1)

  const [tableRowsState, setTableRowsState] = React.useState<any>({})

  const expandOrColapse = (index: number, isin: string | null, expand: boolean) => {
    setTableRowsState({ ...tableRowsState, [index]: expand })
    if (expand && isin != null) {
      getPositions(clientId, portfolios[selectedPortfolioIndex].portfolioId, isin, index)
    }
  }

  const getIntrumentPropertyFromPosition = (property: string, positions: any[]) => {
    if (positions && positions.length > 0) {
      if (positions[0] && positions[0].instrument) {
        return positions[0].instrument[property]
      }
    }
  }

  const getValidSecurity = (securityId: number) => {
    for (let i = 0; i < securities.length; i++) {
      if (securities[i].securityId === securityId) return securities[i]
    }
    return null
  }

  const [index, setIndex] = useState(0)

  const handleChangeIndex = (index: number) => {
    setIndex(index)
  }

  const history = useHistory()

  const handleMenuClick = (index: number, label: Label | null) => {
    setLabelNewTab(false)
    setLabelMenuColor(label?.color || '')
    setLabelMenuName(label?.name || '')
    setLabelMenuLabelId(label?.labelId || -1)
    if (index === 1 && label) {
      dispatch(openDialog(NEW_LABEL_DIALOG_ID))
    }
    if (index === 2 && label) {
      dispatch(openDialog(REMOVE_LABEL_DIALOG_ID))
    }
    setIsHovered(-1)
  }

  const includeSecurity = (securityId: number, label: Label) => {
    if (label && label.securities) {
      for (let i = 0; i < label.securities.length; i++) {
        if (label.securities[i].securityId === securityId) {
          return true
        }
      }
    }
    return false
  }
  return (
    <NewLayout pageName="ASSET_OVERVIEW">
      {loading && <LoadingIndicator />}
      {!loading && (
        <div className={classes.whole}>
          <div className={classes.topContentPC}>
            <DonutChart
              graphBarIndex={graphBarIndex}
              optionsGraphBar={graphBar.options}
              seriesGraphBar={graphBar.series}
              handleChangeAggType={handleChangeAggType}
              client={client}
              portfolio={portfolios[selectedPortfolioIndex]}
            />
            <AreaChart
              graphLineIndex={graphLineIndex}
              handleChangePortfolioAmount={handleChangePortfolioAmount}
              optionsGraphLine={optionsGraphLine}
              seriesGraphLine={seriesGraphLine}
              portfolio={portfolios[selectedPortfolioIndex]}
              labelTab={labelTab}
              labels={labels}
            />
          </div>
          <LabelsBar
            currentLabel={labelTab}
            setCurrentLabel={setLabelTab}
            labels={labels}
            handleMenuClick={handleMenuClick}
            newLabelFunction={() => {
              setLabelNewTab(true)
              dispatch(openDialog(NEW_LABEL_DIALOG_ID))
            }}
          />
          {!isClientUser() && <Notes portfolioId={portfolios[selectedPortfolioIndex]?.portfolioId || 0} />}

          <DataTable
            portfolios={portfolios}
            selectedPortfolio={selectedPortfolioIndex}
            includeSecurity={includeSecurity}
            labelTab={labelTab}
            labels={labels}
            securities={securities}
            setLabelSecurity={setLabelSecurity}
            clientId={clientId}
          />

          <ActionAlert />
          <ActionFailedAlert handleClose={()=>{}}/>
          <OrderInputDialog client={client} portfolio={portfolios[selectedPortfolioIndex]} />
          <NewLabelDialog
            portfolioId={portfolios[selectedPortfolioIndex]?.portfolioId}
            labels={labels}
            newDialog={labelNewTab}
            color={labelMenuColor || ''}
            name={labelMenuName || ''}
            labelId={labelMenuLabelId}
          />
          <RemoveLabelDialog name={labelMenuName} labelId={labelMenuLabelId} />
          <ChoiceLabelDialog
            securityName={labelSecurity.securityName}
            securityId={labelSecurity.securityId}
            labels={labels}
            portfolioId={portfolios[selectedPortfolioIndex]?.portfolioId}
          />
        </div>
      )}
    </NewLayout>
  )
}
