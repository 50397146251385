import { Checkbox, TableCell, TableRow, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { OrderRowData } from './OrderTable'
import classNames from 'classnames'
import { InstrumentIcon } from '../common/InstrumentIcon'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import {
  ORDER_STATE_CANCELED,
  ORDER_STATE_CONFIRMED,
  ORDER_STATE_ERROR,
  ORDER_STATE_EXPIRED,
  ORDER_STATE_FILLED,
  ORDER_STATE_NEW,
  ORDER_STATE_PARTIAL_CANCELED,
  ORDER_STATE_PARTIAL_EXPIRED,
  ORDER_STATE_PARTIAL_FILLED,
  ORDER_STATE_REJECTED,
  ORDER_STATE_SENT_NEW,
} from '../../constants/codeLists'

import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { newThemeObject } from '../../newTheme'
import { formatNumber } from '../../utils/numbersFormatter'
import { ColoredIconButton } from '../common/ColoredIconButton'

import { useDispatch } from 'react-redux'
import { openDialog } from '../../redux/actions/dialogActions'
import EditIcon from '@mui/icons-material/Edit';
import { ORDER_DETAIL_DIALOG_ID } from '../../constants/dialogConstants'

const useStyles = makeStyles(theme => ({
  tableContent: {
    ...newThemeObject.table.tableContent,
  },
  rowOrderState: {
    borderRadius: '1rem',
    height: '2rem',
    width: '100px',
    alignContent: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  rowOrderStateText: {
    opacity: '0.5',
    fontWeight: '500',
    fontSize: '0.7rem',
    margin: 0,
  },
  tableContentIcon: {
    padding: '25px 20px',
  },
  sendToFix: {
    justifyContent: 'center',
    align: 'center',
    display: 'flex',
  },
  columnCell: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    color: '#000',
  },
  rowColorSell: {
    backgroundColor: '#F9F3E5',
  },
  text: {
    fontWeight: '400',
    margin: '0',
    fontSize: '0.7rem',
    textWrap: 'wrap',
  },
  tableCell: {
    paddingLeft: 0,
  },
}))

interface OrderTableRowProps {
  row: OrderRowData
  isLast: boolean,
  setOrderCheck: (orderId: number, valueDiary: boolean) => any
}

const OrderTableRow = ({ row, isLast, setOrderCheck }: OrderTableRowProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const orderDirectionValue = (orderDirection: string) => {
    if (orderDirection === 'OD_BUY') {
      return t('OD_BUY')
    } else {
      return t('OD_SELL')
    }
  }

  const orderValidityType = (validityType: string, validityDate: string) => {
    let result
    if (validityType === 'OV_GTD') {
      result = validityDate ? format(new Date(validityDate), 'dd/MM/yyyy') : ''
    } else {
      result = ''
    }
    return result
  }

  const marketTypeValue = (marketType: string) => {
    if (marketType === 'OMT_LIMIT') {
      return t('OMT_LIMIT')
    } else {
      return t('OMT_MARKET')
    }
  }

  const orderValidityValue = (orderValidity: string | number) => {
    if (orderValidity === 'OV_GTC') {
      return t('OV_GTC')
    } else {
      return t('OV_GTD')
    }
  }

  const getStateColor = (state: string) => {
    if (state === ORDER_STATE_NEW || state === ORDER_STATE_SENT_NEW) {
      return newThemeObject.palette.state.new
    } else if (state === ORDER_STATE_PARTIAL_FILLED ) {
      return newThemeObject.palette.state.partiallyFilled
    } else if ( state === ORDER_STATE_FILLED ) {
      return newThemeObject.palette.state.filled
    } else if ( state === ORDER_STATE_EXPIRED || state === ORDER_STATE_PARTIAL_EXPIRED ) {
      return newThemeObject.palette.state.expired
    } else if ( state === ORDER_STATE_REJECTED ) {
      return newThemeObject.palette.state.rejected
    } else if ( state === ORDER_STATE_CONFIRMED ) {
      return newThemeObject.palette.state.confirmed
    } else if ( state === ORDER_STATE_CANCELED || state === ORDER_STATE_PARTIAL_CANCELED ) {
      return newThemeObject.palette.state.canceled
    } else if ( state === ORDER_STATE_ERROR ) {
      return newThemeObject.palette.state.error
    }
  }

  return (
    <TableRow
      className={classNames([classes.tableContent, row.direction === 'OD_SELL' ? classes.rowColorSell : ''])}
      key={row.orderId}
    >
      <TableCell
        className={classNames([classes.tableContent, classes.tableContentIcon])}
        style={isLast ? { borderBottom: '0' } : {}}
      >
        <InstrumentIcon securityType={row.assetClass} />
      </TableCell>
      <TableCell className={classes.tableCell} style={isLast ? { borderBottom: '0' } : {}}>
        <p className={classes.text}>{row.orderIdent}</p>
      </TableCell>
      <TableCell className={classes.tableCell} style={isLast ? { borderBottom: '0' } : {}}>
        <p className={classes.text}>{format(new Date(row.orderCreationDate), 'dd/MM/yyyy')}</p>
      </TableCell>
      <TableCell className={classes.tableCell} style={isLast ? { borderBottom: '0' } : {}}>
        <div className={classes.columnCell}>
          {row.companyName && <p className={classes.text}>{row.companyName}</p>}
          {!row.companyName && <>
            <p className={classes.text}>{row.surname}</p>
            <p className={classes.text}>{row.firstName}</p>
          </>}
        </div>
      </TableCell>
      <TableCell className={classes.tableCell} style={isLast ? { borderBottom: '0' } : {}}>
        <div className={classes.columnCell}>
          <p className={classes.text}>{orderDirectionValue(row.direction)}</p>
          <p className={classes.text}>{marketTypeValue(row.orderMarketType)}</p>
        </div>
      </TableCell>
      <TableCell className={classes.tableCell} style={isLast ? { borderBottom: '0' } : {}}>
        <div className={classes.columnCell}>
          <p className={classes.text}>{orderValidityValue(row.validityType)}</p>
          <p className={classes.text}>{orderValidityType(row.validityType, row.validityDate)}</p>
        </div>
      </TableCell>
      <TableCell className={classes.tableCell} style={isLast ? { borderBottom: '0' } : {}}>
        <div className={classes.columnCell}>
          <p className={classes.text}>{formatNumber(row.price)}</p>
          <p className={classes.text}>
            {formatNumber(row.quantity, {
              decimalPlaces: 0,
              suffix: `${t('UNIT')}`,
            })}
          </p>
        </div>
      </TableCell>
      <TableCell className={classes.tableCell} style={isLast ? { borderBottom: '0' } : {}}>
        <div className={classes.columnCell}>
          <p className={classes.text}>{formatNumber(row.amount)}</p>
          <p className={classes.text}>{row.currency}</p>
        </div>
      </TableCell>
      <TableCell className={classes.tableCell} style={isLast ? { borderBottom: '0' } : {}}>
        <div className={classes.columnCell}>
          <p className={classes.text}>{row.security}</p>
          <p className={classes.text}>
            {row.isin}
            {row.symbol ? `/${row.symbol}` : ''}
          </p>
        </div>
      </TableCell>
      <TableCell className={classes.tableCell} style={isLast ? { borderBottom: '0' } : {}}>
        <div style={{ backgroundColor: getStateColor(row.state) }} className={classes.rowOrderState}>
          <p className={classes.rowOrderStateText}>{t(row.state)}</p>
        </div>
      </TableCell>
      <TableCell className={classes.tableCell} style={isLast ? { borderBottom: '0' } : {}}>
        <div className={classes.sendToFix}>{row.sendToFix ? <CheckIcon /> : <CloseIcon />}</div>
      </TableCell>
      <TableCell>
        <Checkbox
          disabled={row.diaryExported}
          checked={row.diary}
          onChange={()=>setOrderCheck(row.orderId, row.diary)}
          name="checked"
          color="primary"
        />
      </TableCell>

      <TableCell align="right">
                  <ColoredIconButton
                    // className={classes.editButton}
                    size="small"
                    onClick={() =>
                      dispatch(
                        openDialog(ORDER_DETAIL_DIALOG_ID, {
                          clientId: row.clientId,
                          securityId: row.securityId,
                          orderId: row.orderId,
                        })
                      )
                    }
                    icon={<EditIcon fontSize="small" />}
                  />
                </TableCell>
    </TableRow>
  )
}

export default OrderTableRow
