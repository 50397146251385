export const newThemeObject = {
  palette: {
    main: {
      primary: '#11457F',
      secondary: '#ECECEC',
      background: '#FAFAFC',
      sectext: 'rgba(0,0,0,0.5)',
      highlight: '#F0F3F8',
      dark: '#12457F',
      gray: '#48494B',
      blackAlpha: 'rgba(0,0,0,0.025)',
      error: '#D02F0B',
      bad: '#ff0000',
      lightGray: '#B6b6b6',
      green: '#2A7E19'
    },
    graphColors: ['#11457F', '#D9CC0A', '#30B19A', '#E06A32', '#762A83', '#72A837'],
    text: {
      gray: '#7C7C7C',
    },
    background: {
      lightGray: '#f8f8f8',
      gray: '#f5f5f5',
    },
    highlight: {
      bg: 'rgba(48, 100, 177, 0.08125)',
    },
    orderTiles: {
      main: {
        bg: `linear-gradient(to top, #3064B1, #6785B3)`,
        text: '#FFFFFF',
      },
      secondary: {
        bg: '#ECECEC',
        text: '#000000',
      },
      basic: {
        bg: '#FFFFFF',
        text: '#000000',
      },
    },
    orderTable: {
      searchBar: {
        bg: 'rgba(30,42,177, 0.035)',
      },
    },
    state: {
      new: '#66B2FF',
      confirmed: '#00CED1',
      partiallyFilled: '#FFA500',
      filled: '#32CD32',
      rejected: '#FF4500',
      expired: '#808080',
      canceled: '#e7e7e7',
      error: '#F7D5F2'
    },
    instrument: {
      EQTY: '#d9a50a',
      TypeB: '#6A81BB',
      BOND: '#6A81BB',
      E: '#d9a50a',
      ETF: '#4caf50',
      DERIV: '',
      HYBRID: '',
      MM: '',
      OTHER: '',
    },
    labels: [
      '#cf2e0a',
      '#ff6d42',
      '#ffc34a',
      '#fff236',
      '#c1f375',
      '#62b861',
      '#127a60',
      '#2284b6',
      '#8cd3ff',
      '#b177e2',
      '#ff9dd8',
      '#a06f3a',
      '#424242',
    ],
  },
  roundness: {
    basic: '20px',
    small: '10px',
    left: `20px 0 0 20px`,
    right: `0 20px 20px 0`,
  },
  table: {
    tableHead: {
      background: 'rgba(0,0,0,0.025)',
      margin: '10px 0 0 10px',
      height: '2rem',
      padding: '0',
      borderBottom: 'none',
    },
    tableHeadChildrenText: {
      margin: '0',
      opacity: '0.5',
      padding: '0',
    },
    tableHeadChildren: {
      borderBottom: 'none',
      align: 'left',
      padding: 0,
    },
    tableHeadChildrenFirst: {
      paddingLeft: 20,
      paddingRight: 0,
      borderRadius: `20px 0 0 20px`,
    },
    tableHeadChildrenLast: {
      borderRadius: `0 20px 20px 0`,
    },
    tableContent: {
      fontSize: '30px',
      padding: '25px 0px',
    },
    tableContentIcon: {
      padding: '25px 20px',
      paddingRight: 0,
    },
    tableContentFirst: {
      paddingLeft: 20,
      paddingRight: 0,
    },
    tableContainer: {
      padding: '10px',
      borderRadius: 20,
    },
    tableContentText: {
      fontWeight: '400',
      margin: '0',
      fontSize: '0.7rem',
      textWrap: 'wrap',
    },
    tableBody: {
      margin: '0 70px',
    },
  },
  padding: {
    wrappers: '1rem 0.5rem',
    smallWrapper: '0.5rem',
  },
}
