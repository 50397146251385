import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, Button, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface Props {
  alertOpen: boolean
  onOk: () => void
  onCancle: () => void
  alertText: string
}

export const AlertMessageConfirmation: React.FC<Props> = ({ alertOpen, onOk, onCancle, alertText}) => {
  const { t } = useTranslation();
  return (
    <div>
      <Dialog open={alertOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{alertText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" autoFocus onClick={onCancle} >{t('CANCLE')}</Button>
          <Button color="primary" autoFocus onClick={onOk} >{t('OK')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
