import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { formatNumber } from '../utils/numbersFormatter'
import { useTranslation } from 'react-i18next'
import { ClientCashflow, ClientDeal } from '../redux/reducers/clients'
import { NewLayout } from '../components/newLayout/NewLayout'
import { newThemeObject } from '../newTheme'
import { OrderDetailDialog } from '../components/dialogs/OrderDetailDialog'
import { FileImportDialog } from '../components/dialogs/FileImportDialog'
import CardWrapper from '../components/common/CardWrapper'
import classNames from 'classnames'
import { useSelector } from '../redux/hooks/useSelector'
import { LoadingIndicator } from '../components/common/LoadingIndicator'
import { useClientApi } from '../hooks/useClientApi'
import { getClientIdFromUser, isClientUser } from '../services/keycloak.service'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@date-io/date-fns'

const useStyles = makeStyles(theme => ({
  title: {
    color: newThemeObject.palette.main.primary,
    margin: 0,
  },
  paper: {
    overflowY: 'auto',
    overflowX: 'auto',
    gap: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  topBar: {
    margin: '0.2rem 2rem',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1.2rem',
    fontWeight: 600,
  },
  table: {
    minWidth: 1000,
  },
  tableHead: {
    ...newThemeObject.table.tableHead,
  },
  tableHeadChildrenText: {
    ...newThemeObject.table.tableHeadChildrenText,
  },
  tableHeadChildren: {
    ...newThemeObject.table.tableHeadChildren,
  },
  tableHeadChildrenFirst: {
    ...newThemeObject.table.tableHeadChildrenFirst,
  },
  tableHeadChildrenLast: {
    ...newThemeObject.table.tableHeadChildrenLast,
  },
  tableContent: {
    ...newThemeObject.table.tableContent,
  },
  tableContentFirst: {
    ...newThemeObject.table.tableContentFirst,
  },
  firstClickableRow: {
    cursor: 'pointer',
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  lastRow: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  tableRow: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
      borderTop: 'thin solid',
      borderColor: 'rgba(0, 0, 0, 0.12)',
    },
  },
  text: {
    fontWeight: '400',
    margin: '0',
    fontSize: '0.7rem',
    textWrap: 'wrap',
  },

  tableToolBar: {
    minHeight: 0,
    display: 'flex',
    alignContent: 'space-around',
    width: '100%',
    padding: 0,
    paddingBottom: 20,
    margin: 0,
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  textField: {
    width: '100%',
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      marginRight: theme.spacing(1.5),
      maxWidth: '180px',
    },
  },
  filler: {
    flexGrow: 1,
  },
  icon: {
    color: newThemeObject.palette.main.sectext,
    backgroundColor: '#ffffff',
  },
}))

export const ClientDetailCashflow = () => {
  const classes = useStyles()

  const loading = useSelector(state => state.clients.loading)
  const client = useSelector(state => state.clients.client)
  const clientCashflowsTmp = useSelector(state => state.clients.clientCashflows)

  const { getClientCashflow } = useClientApi()
  const { getClient } = useClientApi()
  const { clientId } = useParams<{ clientId: string }>()
  const { t } = useTranslation()

  const getClientId = () => {
    if (isClientUser()) {
      return getClientIdFromUser()
    } else {
      return clientId
    }
  }
  
  const clientId2 = getClientId()
  useEffect(() => {
    getClient(Number(clientId2))
  }, [])


  useEffect(()=>{
    if ( client && client.clientId!==0 ) {
      getClientCashflow(client.clientCounterpartyId, '', '');
    }
  }, [client])

  const [dateFrom, setDateFrom] = useState<Date>();
  const [dateTo, setDateTo] = useState<Date>();
  const [search, setSearch] = useState<string>();

  const [clientCashflow, setClientCashflow] = useState<ClientCashflow[]>([]);

  const onDateFromChange = (name: string) => (date: Date | null) => {
    setDateFrom(date || undefined)
  }

  const onDateToChange = (name: string) => (date: Date | null) => {
    setDateTo(date || new Date())
  }

  const onOrderTypeSearchChange = (event: React.ChangeEvent<{ value: string }>) => {
    setSearch(event.target.value)
  }

  useEffect(()=>{
    if ( clientCashflowsTmp ) {
      if ( clientCashflowsTmp.length === 0 ) {
        setClientCashflow([]);
        return
      }
      
      let tmp = [...clientCashflowsTmp];

      if ( dateFrom ) {
        tmp = tmp.filter((item)=>(new Date(item.valueDate)>dateFrom))
      }
      if ( dateTo ) {
        tmp = tmp.filter((item)=>(new Date(item.valueDate)<dateTo))
      }
      if ( search ) {
        tmp = tmp.filter((item)=>(
          // ( item.firstName && item.firstName.toUpperCase().includes(search.toUpperCase()) )
          // || ( item.surname && item.surname.toUpperCase().includes(search.toUpperCase()) )
          ( item.text && item.text.toUpperCase().includes(search.toUpperCase()) )
          || ( item.tpid && item.tpid.toUpperCase().includes(search.toUpperCase()) )
          || ( item.currency && item.currency.toUpperCase().includes(search.toUpperCase()) )
        ));
      }
      setClientCashflow(tmp);
    }
  }, [clientCashflowsTmp, dateFrom, dateTo, search])


  return (
    <NewLayout pageName="TRANSACTIONS">

      <Toolbar variant="dense" className={classes.tableToolBar}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            renderInput={params => <TextField {...params} variant="standard" className={classes.textField} label={t('DATE_FROM2')}/>}
            inputFormat="dd/MM/yyyy"
            value={dateFrom || null}
            onChange={onDateFromChange('dateFromId')}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            renderInput={params => (
              <TextField {...params} variant="standard" className={classes.textField} label={t('DATE_TO')} />
            )}
            inputFormat="dd/MM/yyyy"
            value={dateTo || null}
            onChange={onDateToChange('dateToId')}
          />
        </LocalizationProvider>

        <TextField
          variant="standard"
          className={classes.textField}
          placeholder={t('SEARCH')}
          label={t('SEARCH')}
          onChange={onOrderTypeSearchChange}
          value={search}
        />
      </Toolbar>

      {loading && <LoadingIndicator />}
      {!loading && 
        <TableContainer component={CardWrapper} className={classes.paper} key={66}>
              <span className={classes.topBar}>
                <p className={classes.title}>{t("CASHFLOW_TRANSACTION")}</p>
              </span>
              <Table className={classes.table} size="small">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell className={classNames([classes.tableHeadChildren, classes.tableHeadChildrenFirst])}>
                      <p className={classes.tableHeadChildrenText}>{t('FIRST_NAME')}</p>
                    </TableCell>
                    <TableCell className={classes.tableHeadChildren}>
                      <p className={classes.tableHeadChildrenText}>{t('SURNAME')}</p>
                    </TableCell>
                    <TableCell className={classes.tableHeadChildren}>
                      <p className={classes.tableHeadChildrenText}>{t('PORTFOLIO')}</p>
                    </TableCell>
                    <TableCell className={classes.tableHeadChildren}>
                      <p className={classes.tableHeadChildrenText}>{t('AMOUNT')}</p>
                    </TableCell>
                    <TableCell className={classes.tableHeadChildren}>
                      <p className={classes.tableHeadChildrenText}>{t('CURRENCY')}</p>
                    </TableCell>
                    <TableCell className={classes.tableHeadChildren}>
                      <p className={classes.tableHeadChildrenText}>{t('TRADE_DATE')}</p>
                    </TableCell>
                    <TableCell className={classes.tableHeadChildren}>
                      <p className={classes.tableHeadChildrenText}>{t('VALUE_DATE')}</p>
                    </TableCell>
                    <TableCell className={classes.tableHeadChildren}>
                      <p className={classes.tableHeadChildrenText}>{t('TEXT')}</p>
                    </TableCell>
                    <TableCell className={classes.tableHeadChildren}>
                      <p className={classes.tableHeadChildrenText}>{t('DESCRIPTION')}</p>
                    </TableCell>
                    <TableCell className={classNames([classes.tableHeadChildren, classes.tableHeadChildrenLast])} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clientCashflow.map((clientDeal: ClientCashflow, index: number) => (
                    <React.Fragment key={index}>
                      <TableRow
                        className={classNames(index === 0 ? classes.firstClickableRow : classes.tableRow)}
                      >
                        <TableCell className={classes.tableContent}>
                          <p className={classes.text}>{clientDeal.firstName}</p>
                        </TableCell>
                        <TableCell className={classes.tableContent}>
                          <p className={classes.text}>{clientDeal.surname}</p>
                        </TableCell>
                        <TableCell className={classes.tableContent}>
                          <p className={classes.text}>{clientDeal.portfolio}</p>
                        </TableCell>
                        <TableCell className={classes.tableContent}>
                          <p className={classes.text}>{formatNumber(clientDeal.amount)}</p>
                        </TableCell>
                        <TableCell className={classes.tableContent}>
                          <p className={classes.text}>{clientDeal.currency}</p>
                        </TableCell>
                        <TableCell className={classes.tableContent}>
                          <p className={classes.text}>{clientDeal.tradeDate}</p>
                        </TableCell>
                        <TableCell className={classes.tableContent}>
                          <p className={classes.text}>{clientDeal.valueDate}</p>
                        </TableCell>
                        <TableCell className={classes.tableContent}>
                          <p className={classes.text}>{clientDeal.text}</p>
                        </TableCell>
                        <TableCell className={classes.tableContent}>
                          <p className={classes.text}>{clientDeal.tpid}</p>
                        </TableCell>

                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
        </TableContainer>
      }

      <OrderDetailDialog />
      <FileImportDialog />
    </NewLayout>
  )
}
