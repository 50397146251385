import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ActiveOrdersTiles } from '../components/common/ActiveOrdersTiles'
import { ActionAlert } from '../components/dialogs/ActionAlert'
import { ActionFailedAlert } from '../components/dialogs/ActionFailedAlert'
import { FileImportDialog } from '../components/dialogs/FileImportDialog'
import { MassOrderInputDialog } from '../components/dialogs/MassOrderInputDialog'
import { OrderDisplay } from '../components/OrderList/OrderDisplay'
import { OrderTableTopBar } from '../components/OrderList/OrderTableTopBar'
import { useParams } from 'react-router'
import { useSelector } from '../redux/hooks/useSelector'
import { useClientApi } from '../hooks/useClientApi'
import { useInstrumentApi } from '../hooks/useInstrumentApi'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { OrdersByStateCount } from '../redux/reducers/orders'
import { ExpandMore } from '@mui/icons-material'
import { newThemeObject } from '../newTheme'
import useMobileWidth from '../hooks/useMobileWidth'
import { NewLayout } from '../components/newLayout/NewLayout'
import { initializeSseOrder } from '../hooks/useOrderSseApi'

const useStyles = makeStyles(theme => ({
  orderDisplayTable: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(6),
    flexGrow: 1,
  },

  searchBar: {
    background: newThemeObject.palette.orderTable.searchBar.bg,
    padding: 0,
    borderRadius: '15px',
    margin: '20px 0',
    borderTop: 0,
    '&.Mui-expanded': {
      margin: '20px 0',
    },
    '&::before': {
      display: 'none',
    },
  },
  searchBarHead: {
    padding: 0,
    margin: 0,
    dispay: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  searchBarHeadText: {
    margin: 0,
    padding: 0,
  },
  clientEssentials: {
    width: '25%',
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
  manIcon: {
    marginTop: theme.spacing(1),
  },
  clientPersonalInfoColumn: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1),
  },
  accDetail: {
    padding: theme.spacing(2),
  },
  test: {
    background: 'red',
    width: '200px',
    position: 'absolute',
    top: '20px',
    left: '-50px',
    opacity: '0.95',
    textAlign: 'center',
    lineHeight: '25px',
    fontSize: '16px',
    letterSpacing: '1px',
    color: '#f0f0f0',
    transform: 'rotate(-45deg)',
    paddingRight: '18px',
  },
}))

const ribon = process.env.REACT_APP_ENV_RIBBON

export const ClientOrderList = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { clientId } = useParams<{ clientId: string }>()
  const { instrumentName } = useParams<{ instrumentName: string }>()
  const client = useSelector(state => state.clients.client)
  const { getClient } = useClientApi()
  const { getOrderTableRows, updateOrderDiary } = useInstrumentApi()
  const { t } = useTranslation()

  const [dateFrom, setDateFrom] = useState<Date>(new Date())
  const [dateTo, setDateTo] = useState<Date>(new Date())

  useEffect(() => {
    initializeSseOrder(dispatch, null, dateFrom, dateTo)
  }, [dateFrom, dateTo])

  const [ordersByStateCount, setOrdersByStateCount] = useState([] as OrdersByStateCount[])
  const orderSse = useSelector(state => state.orders.orderSse)

  const isMobile = useMobileWidth()
  const [filtersExpanded, setFiltersExpanded] = useState(isMobile)
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const setOrderCheckInner = (orderId: number, valueDiary: boolean) => {
    updateOrderDiary(orderId, !valueDiary);
  }

  useEffect(() => {
    isMobile ? setFiltersExpanded(false) : setFiltersExpanded(true)
  }, [isMobile])

  useEffect(() => {
    if (orderSse && orderSse.userStates && orderSse.userStates.length > 0) {
      setOrdersByStateCount([...orderSse.userStates[0].states])
    }
  }, [orderSse])

  useEffect(() => {
    getClient(Number(clientId))
  }, [])

  useEffect(() => {
    if (client) {
      getOrderTableRows(null, null, client.clientId, instrumentName)
    }
  }, [client])

  const history = useHistory()

  if (!client) return null

  const handleChange = () => {
    setFiltersExpanded(!filtersExpanded)
  }

  return (
    <NewLayout pageName="ORDER_OVERVIEW">
      <ActiveOrdersTiles ordersByStateCount={ordersByStateCount} />
      <Accordion elevation={0} className={classes.searchBar} expanded={filtersExpanded} onChange={handleChange}>
        <AccordionSummary expandIcon={<ExpandMore />} className={classes.searchBarHead}>
          <p className={classes.searchBarHeadText}>Filtry</p>
        </AccordionSummary>
        <AccordionDetails className={classes.accDetail}>
          <OrderTableTopBar
            dateFrom={dateFrom}
            setDateFrom={setDateFrom}
            dateTo={dateTo}
            setDateTo={setDateTo}
            setShowConfirmation={setShowConfirmation}
        />
        </AccordionDetails>
      </Accordion>
      <OrderDisplay
        setOrderCheck={setOrderCheckInner}
      />

      <MassOrderInputDialog />
      <FileImportDialog />
      <ActionAlert />
      <ActionFailedAlert handleClose={()=>{}}/>
    </NewLayout>
  )
}
