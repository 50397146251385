import makeStyles from '@mui/styles/makeStyles'
import { ClientDisplay } from '../components/ClientList/ClientDisplay'
import { ClientTableTopBar } from '../components/ClientList/ClientTableTopBar'
import { ClientInformationDialog } from '../components/dialogs/ClientInformationDialog'
import { FileImportDialog } from '../components/dialogs/FileImportDialog'
import { MassOrderInputDialog } from '../components/dialogs/MassOrderInputDialog'
import { useTranslation } from 'react-i18next'
import { NewLayout } from '../components/newLayout/NewLayout'
import { useState } from 'react'
import { AlertMessageConfirmation } from '../components/dialogs/AlertMessageConfirmation'
import { useClientFilters } from '../hooks/useClientFilters'
import { useClientApi } from '../hooks/useClientApi'

const useStyles = makeStyles(theme => ({
  clientDisplayTable: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  clientEssentials: {
    width: '25%',
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
  test: {
    background: 'red',
    width: '200px',
    position: 'absolute',
    top: '20px',
    left: '-50px',
    opacity: '0.95',
    textAlign: 'center',
    lineHeight: '25px',
    fontSize: '16px',
    letterSpacing: '1px',
    color: '#f0f0f0',
    transform: 'rotate(-45deg)',
    paddingRight: '18px',
  },
}))

export const ClientList = () => {
  const { t } = useTranslation()
  
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [clientsToExport, setClientsToExport] = useState<number[]>([]);
  const clients = useClientFilters();
  const { exportClients } = useClientApi();

  const setShowConfirmationDialog = (open: boolean) => {
    let tmp = [];
    for ( let i=0; i<clients.length; i++) {
      tmp.push(clients[i].clientId);
    }
    setClientsToExport(tmp)
    setShowConfirmation(open);
  }

  const handleConfirmation = () => {
    exportClients(clientsToExport)
    setShowConfirmation(false);
    setClientsToExport([]);
  }

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  }

  return (
    <NewLayout pageName={t('CLIENT_OVERVIEW')}>
      <ClientTableTopBar 
        setShowConfirmation={setShowConfirmationDialog}
      />
      <ClientDisplay />
      <ClientInformationDialog />
      <MassOrderInputDialog />
      <FileImportDialog />
      <AlertMessageConfirmation
        alertOpen={showConfirmation}
        onOk={handleConfirmation}
        onCancle={handleCloseConfirmation}
        alertText={t('CLIENT_EXPORT_MESSAGE')}
      />
    </NewLayout>
  )
}
