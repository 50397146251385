import { AppBar, Grid, SelectChangeEvent, TextField, Toolbar, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect } from 'react'
import { ColoredIconButton } from '../common/ColoredIconButton'
import AddIcon from '@mui/icons-material/Add'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { useSelector } from '../../redux/hooks/useSelector'
import { useDispatch } from 'react-redux'
import { openDialog } from '../../redux/actions/dialogActions'
import { CLIENT_INFORMATION_DIALOG_ID } from '../../constants/dialogConstants'
import {
  filterClientAmountDown,
  filterClientAmountUp,
  filterClientName,
  sortClients,
} from '../../redux/actions/filterActions'
import { ClientSortOptions } from '../../redux/reducers/filters'
import { NumericInput } from '../common/NumericInput'
import { useClientApi } from '../../hooks/useClientApi'
import { useTranslation } from 'react-i18next'
import { newThemeObject } from '../../newTheme'
import DownloadIcon from '@mui/icons-material/Download';

const useStyles = makeStyles(theme => ({
  tableTopBar: {
    backgroundColor: newThemeObject.palette.orderTable.searchBar.bg,
    borderRadius: newThemeObject.roundness.basic,
    marginBottom: theme.spacing(2),
  },
  tableToolBar: {
    minHeight: 0,
    display: 'flex',
    gap: theme.spacing(1),
    width: '100%',
    padding: theme.spacing(2),
    margin: 0,
    flexDirection: 'column',
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  separator: {
    flexGrow: 1,
  },
  textField: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
    marginRight: theme.spacing(1),
  },
  icon: {
    color: newThemeObject.palette.main.sectext,
    backgroundColor: '#ffffff',
  },
}))

interface ClientTableTopBarProps {
  setShowConfirmation: (param: boolean) => any
}

export const ClientTableTopBar = ({
  setShowConfirmation,
}: ClientTableTopBarProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { getClientsByParam, getClients } = useClientApi()
  const { t } = useTranslation()

  const filters = useSelector(state => state.filters)
  const user = useSelector(state => state.auth.user)

  const onSortByChange = (event: SelectChangeEvent<string>) => {
    dispatch(sortClients(event.target.value))
  }

  const onNameSearchFieldChange = (event: React.ChangeEvent<{ value: string }>) => {
    dispatch(filterClientName(event.target.value))
  }

  const onInstrumentSearchFieldChange = (event: React.ChangeEvent<{ value: string }>) => {
    if (event.target.value === '') getClients()
    else getClientsByParam(event.target.value)
  }

  const onAmountUpSearchFieldChange = (event: React.ChangeEvent<{ value: string }>) => {
    dispatch(filterClientAmountUp(event.target.value))
  }

  const onAmountDownSearchFieldChange = (event: React.ChangeEvent<{ value: string }>) => {
    dispatch(filterClientAmountDown(event.target.value))
  }

  const showExportButton = () => {
    if ( user && user.roles ) {
      for ( let i=0; i<user.roles.length; i++) {
        if ( user.roles[i]==='CEO' 
          ||  user.roles[i]==='PORTF MANAGER' 
          || user.roles[i]==='ASSISTANT' ) {
          return true;
        }
      }
    } else {
      return false;
    }
  }

  useEffect(() => {
    dispatch(filterClientName(''))
  }, [])

  return (
    <AppBar position="static" className={classes.tableTopBar} elevation={0}>
      <Toolbar variant="dense" className={classes.tableToolBar}>
        <TextField
          className={classes.textField}
          placeholder={t('CLIENT')}
          onChange={onNameSearchFieldChange}
          value={filters.searchedClientName}
          variant="standard"
        />

        <NumericInput
          className={classes.textField}
          placeholder={t('AMOUNT_FROM')}
          onChange={onAmountUpSearchFieldChange}
          value={filters.searchedAmountUp}
          variant="standard"
        />

        <NumericInput
          className={classes.textField}
          placeholder={t('AMOUNT_TO')}
          onChange={onAmountDownSearchFieldChange}
          value={filters.searchedAmountDown}
          variant="standard"
        />
        <TextField
          className={classes.textField}
          placeholder={t('INSTRUMENT')}
          variant="standard"
          onChange={onInstrumentSearchFieldChange}
        />
        <Select value={filters.sortBy} onChange={onSortByChange} variant="standard">
          <MenuItem value={ClientSortOptions.Az}>{t('NAME_A_Z')}</MenuItem>
          <MenuItem value={ClientSortOptions.Za}>{t('NAME_Z_A')}</MenuItem>
          <MenuItem value={ClientSortOptions.AmountDown}>{t('INVESTED_AMOUNT_DESC')}</MenuItem>
          <MenuItem value={ClientSortOptions.AmountUp}>{t('INVESTED_AMOUNT_ASK')}</MenuItem>
        </Select>
        <span className={classes.separator} />

        { showExportButton() &&
          <ColoredIconButton
            className={classes.icon}
            onClick={() => {
              setShowConfirmation(true);
            }}
            size="small"
            icon={<DownloadIcon />}
          />
        }

        <ColoredIconButton
          icon={<AddIcon />}
          className={classes.icon}
          size="small"
          onClick={() => {
            dispatch(
              openDialog(CLIENT_INFORMATION_DIALOG_ID, {
                dialogTitle: t('NEW_CLIENT'),
                type: 'new',
              })
            )
          }}
        />
      </Toolbar>
    </AppBar>
  )
}
