import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  deleteOrderRequest,
  deleteOrdersRequest,
  exportOrdersRequest,
  getCodeListRequest,
  getInstrumentsByParamRequest,
  getOrderRequest,
  getOrdersByStateCountRequest,
  getOrdersRequest,
  getOrderTableRowsRequest,
  getPositionsRequest,
  getSecuritiesRequest,
  getSecurityRequest,
  getSecurityPricesRequest,
  importRequest,
  postMassOrderRequest,
  postOrderRequest,
  updateOrderRequest,
  postSecurityRequest,
  putSecurityRequest,
  getCurrenciesRequest,
  getMarketsRequest,
  updateOrderDiaryRequest,
  exportOrderDiaryRequest,
} from '../api/instrumentApi'
import {
  getOrderAction,
  getOrdersAction,
  getOrdersByStateCountAction,
  getOrderTableRowsAction,
  getPositionsAction,
  getSecuritiesAction,
  getSecurityAction,
  getSecurityPricesAction,
  updateOrderAction,
} from '../redux/actions/instrumentActions'
import { Order } from '../redux/reducers/orders'
import { Security } from '../redux/reducers/securities'
import { CodeList } from '../types/common'
import { usePortfolioApi } from './usePortfolioApi'
import { lookup } from 'mime-types'
import {
  ACTION_FAILED_ALERT_ID,
  ACTION_SUCESS_ALERT_ID,
  FILE_IMPORT_DIALOG_ID,
  INSTRUMENT_INPUT_DIALOG_ID,
  MASS_ORDER_INPUT_DIALOG_ID,
  ORDER_EDIT_DIALOG_ID,
  ORDER_INPUT_DIALOG_ID,
} from '../constants/dialogConstants'
import { closeDialog, openDialog, openDialogMessage } from '../redux/actions/dialogActions'
import { finishLoading, startLoading } from '../redux/actions/loadingActions'
import { getMarketsAction } from '../redux/actions/marketActions'
import { getCurrenciesAction } from '../redux/actions/currencyActions'
import { OrderRowData } from '../components/OrderList/OrderTable'

export const useInstrumentApi = () => {
  const dispatch = useDispatch()
  const [codeList, setCodeList] = useState<CodeList[]>()
  const { getPortfolio } = usePortfolioApi()

  const getPositions = useCallback(async (clientId: String, portfolioId: Number, isin: String, positionId: number) => {
    await getPositionsRequest(clientId, portfolioId, isin)
      .then(response => {
        dispatch(getPositionsAction(response.data, positionId))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const getSecurity = useCallback(async (securityId: Number) => {
    await getSecurityRequest(securityId)
      .then(response => {
        dispatch(getSecurityAction(response.data as Security))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const postSecurity = useCallback(async (security: Security) => {
    await postSecurityRequest(security)
      .then(response => {
        dispatch(getSecurityAction(response.data))
        dispatch(closeDialog(INSTRUMENT_INPUT_DIALOG_ID))
      })
      .catch(error => {
        console.error(error)
        dispatch(openDialog(ACTION_FAILED_ALERT_ID))
      })
  }, [])

  const updateSecurity = useCallback(async (security: Security) => {
    await putSecurityRequest(security)
      .then(response => {
        dispatch(getSecurityAction(response.data))
        dispatch(closeDialog(ORDER_EDIT_DIALOG_ID))
      })
      .catch(error => {
        console.log(error)
        dispatch(openDialog(ACTION_FAILED_ALERT_ID))
      })
  }, [])

  const getSecurities = useCallback(async () => {
    await getSecuritiesRequest()
      .then(response => {
        dispatch(getSecuritiesAction(response.data))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const getSecurityPrices = useCallback(async (securityId: Number) => {
    await getSecurityPricesRequest(securityId)
      .then(response => {
        dispatch(getSecurityPricesAction(response.data))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const getCurrencies = useCallback(async () => {
    await getCurrenciesRequest()
      .then(response => {
        dispatch(getCurrenciesAction(response.data))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const getMarkets = useCallback(async () => {
    await getMarketsRequest()
      .then(response => {
        dispatch(getMarketsAction(response.data))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const getInstrumentsByParam = useCallback(async (param: string, all: boolean) => {
    await getInstrumentsByParamRequest(param, all)
      .then(response => {
        dispatch(getSecuritiesAction(response.data))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const getOrder = useCallback(async (orderId: Number) => {
    await getOrderRequest(orderId)
      .then(response => {
        dispatch(getOrderAction(response.data as Order))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const getOrders = useCallback(async () => {
    await getOrdersRequest()
      .then(response => {
        dispatch(getOrdersAction(response.data))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const getCodeList = useCallback(async () => {
    await getCodeListRequest()
      .then(response => {
        setCodeList(response.data)
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const postOrder = useCallback(async (order: Order) => {
    await postOrderRequest(order, order.portfolio.portfolioId)
      .then(response => {
        getPortfolio(order.portfolio.portfolioId)
        dispatch(closeDialog(ORDER_INPUT_DIALOG_ID))
        getOrdersByStateCount(null)
      })
      .catch(error => {
        console.error(error)
        dispatch(openDialog(ACTION_FAILED_ALERT_ID))
      })
  }, [])

  const updateOrder = useCallback(async (order: Order) => {
    await updateOrderRequest(order)
      .then(response => {
        dispatch(updateOrderAction(response.data))
        dispatch(closeDialog(ORDER_EDIT_DIALOG_ID))
      })
      .catch(error => {
        console.log(error)
        dispatch(openDialog(ACTION_FAILED_ALERT_ID))
      })
  }, [])

  const updateOrderDiary = useCallback(async (orderId: number, diaryValue: boolean) => {
    await updateOrderDiaryRequest(orderId, diaryValue)
      .then()
      .catch(error => {
        console.log(error)
      })
  }, [])

  const exportOrderDiary = useCallback(async (ordersId: number[]) => {
    dispatch(startLoading('exportFile'))
    await exportOrderDiaryRequest(ordersId)
      .then(async response => {
        dispatch(finishLoading('exportFile'))
        const link = document.createElement('a')
        const type = lookup(response.data.name)
        console.warn(type)
        link.href = `data:${type};base64,${response.data.content}`
        link.download = response.data.name
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .catch(error => {
        dispatch(finishLoading('exportFile'))
        dispatch(openDialog(ACTION_FAILED_ALERT_ID))
        console.log(error)
      })
  }, [])

  const postMassOrder = useCallback(async (massOrder: Order[]) => {
    await postMassOrderRequest(massOrder)
      .then(response => {
        dispatch(closeDialog(MASS_ORDER_INPUT_DIALOG_ID))
        getOrdersByStateCount(null)
      })
      .catch(error => {
        console.error(error)
        dispatch(openDialog(ACTION_FAILED_ALERT_ID))
      })
  }, [])

  const getOrdersByStateCount = useCallback(async (clientId: number | null) => {
    await getOrdersByStateCountRequest(clientId)
      .then(response => {
        dispatch(getOrdersByStateCountAction(response.data))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const getOrderTableRows = useCallback(
    async (state: string | null, clientName: string | null, clientId: number | null, securityName: string | null) => {
      await getOrderTableRowsRequest(state, clientName, clientId, securityName)
        .then(response => {
          dispatch(getOrderTableRowsAction(response.data))
        })
        .catch(error => {
          console.error(error)
        })
    },
    []
  )

  const exportOrders = useCallback(async () => {
    dispatch(startLoading('exportFile'))
    await exportOrdersRequest()
      .then(async response => {
        dispatch(finishLoading('exportFile'))
        const link = document.createElement('a')
        const type = lookup(response.data.name)
        console.warn(type)
        link.href = `data:${type};base64,${response.data.content}`
        link.download = response.data.name
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        getOrdersByStateCount(null)
      })
      .catch(error => {
        dispatch(finishLoading('exportFile'))
        dispatch(openDialog(ACTION_FAILED_ALERT_ID))
        console.error(error)
      })
  }, [])

  const importFile = useCallback(async orders => {
    dispatch(startLoading('importFile'))
    await importRequest(orders)
      .then(response => {
        dispatch(finishLoading('importFile'))
        dispatch(openDialogMessage(ACTION_SUCESS_ALERT_ID, response.data))
        // dispatch(closeDialog(FILE_IMPORT_DIALOG_ID))
      })
      .catch(error => {
        dispatch(finishLoading('importFile'))
        console.error(error)
        dispatch(openDialog(ACTION_FAILED_ALERT_ID))
      })
  }, [])

  const deleteOrder = useCallback(async (order: Order) => {
    await deleteOrderRequest(order)
      .then(response => {
        console.log(response.data)
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const deleteOrders = useCallback(async (orders: OrderRowData[]) => {
    await deleteOrdersRequest(orders)
      .then(response => {
        console.log(response.data)
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  return {
    getSecurity,
    getSecurities,
    getInstrumentsByParam,
    getOrder,
    getOrders,
    getPositions,
    postOrder,
    updateOrder,
    updateOrderDiary,
    exportOrderDiary,
    getCodeList,
    postMassOrder,
    codeList,
    getOrdersByStateCount,
    getOrderTableRows,
    exportOrders,
    importFile,
    deleteOrder,
    deleteOrders,
    getSecurityPrices,
    postSecurity,
    updateSecurity,
    getCurrencies,
    getMarkets,
  }
}
